const template = document.createElement('template');
template.innerHTML = `
<header id="header">
    <div class="container">
        <div class="row">
            <a href="/" class="logo go-to-section" data-section="1"></a>
            <div id="menuIcon">
                <input id="menuIconCheckbox" type="checkbox" />
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>

    <div id="mainMenu">
        <button id="mainMenuClose">&times;</button>
        <a href="/#1" class="logo go-to-section" data-section="1"></a>
        <ul>
            <li><a href="/#1" class="go-to-section" data-section="1">Home</a></li>
            <li><a href="/#2" class="go-to-section" data-section="2">Partner</a></li>
            <li><a href="/#3" class="go-to-section" data-section="3">Vorteile</a></li>
            <li><a href="/#4" class="go-to-section" data-section="4">Produkte</a></li>
            <li><a href="/#5" class="go-to-section" data-section="5">Vorstellung</a></li>
            <li><a href="/#6" class="go-to-section" data-section="6">Vergleich</a></li>
            <li><a href="/#7" class="go-to-section" data-section="7">Anbindung</a></li>
            <li><a href="/#8" class="go-to-section" data-section="8">Kontaktaufnahme</a></li>
            <li><a href="/#9" class="go-to-section" data-section="9">Newsletter</a></li>
        </ul>
        <ul>
            <li><a href="/ueber-uns.html">Über uns</a></li>
            <li><a href="/faq.html">FAQ</a></li>
            <li><a href="/manuelle-anbindung.html">Manuelle Anbindung</a></li>
            <li><a href="/versandkosten.html">Versandkosten</a></li>
            <li><a href="https://katalog.dropiverse.de/" target="_blank">Produktkatalog</a></li>
            <li><a href="/impressum.html">Impressum</a></li>
        </ul>
    </div>
    <div id="mainMenuBg"></div>
</header>`;

class Header extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template.innerHTML;

        let html = document.documentElement,
            menu = document.getElementById('mainMenu'),
            menuBg = document.getElementById('mainMenuBg'),
            menuIcon = document.getElementById('menuIcon'),
            menuIconCheckbox = document.getElementById('menuIconCheckbox'),
            mainMenuClose = document.getElementById('mainMenuClose'),
            menulinks = document.getElementsByTagName('li');
        for (let i = 0; i < menulinks.length; i++) {
            menulinks[i].addEventListener('click', function (event) {
                closeOverlay();
            });
        }

        function openOverlay() {
            html.classList.add('no-scroll');
            menuBg.classList.add('open');
            menu.classList.add('open');
        }
        function closeOverlay() {
            html.classList.remove('no-scroll');
            menu.classList.remove('open');
            menuBg.classList.remove('open');
            menuIconCheckbox.checked = false;
        }

        // Add event listeners
        menuIcon.onclick = openOverlay;
        menuBg.onclick = closeOverlay;
        mainMenuClose.onclick = closeOverlay;

        let header = document.getElementById('header');
        window.addEventListener('scroll',function() {
            if (window.scrollY > 50) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        });
    }
}
window.customElements.define('wc-header', Header);